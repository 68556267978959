import { Component, OnInit } from '@angular/core';
import { LatLng } from 'src/app/model/latlng.model';
import { AggressionService } from 'src/app/service/aggression.service';

@Component({
  selector: 'app-view-map',
  templateUrl: './view-map.component.html',
  styleUrls: ['./view-map.component.scss']
})
export class ViewMapComponent implements OnInit {

  constructor(private readonly aggressionService: AggressionService) { }
  
  center: LatLng;
  coordinates: LatLng[]

  ngOnInit(): void {
    console.log("ViewMap loaded");
    this.center = { lat : 48.864716, lng : 2.349014 } as LatLng; // Paris
    this.coordinates = this.aggressionService.getAllAggression().map(a => {
      return { 
        lat : a.latitude, 
        lng : a.longitude 
      } as LatLng 
    });
  }
}

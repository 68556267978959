import { PipeTransform, Pipe } from '@angular/core';
import { Gender } from 'src/app/model/user.model';

export const genderLabel = {
    MALE : 'Homme',
    FEMALE : 'Femme',
    NO_BINARY : 'Non binaire',
    NO_ANSWER : 'Pas de réponse'
}

@Pipe({
    name: 'gender'
})
export class GenderPipe implements PipeTransform{
    transform(value: any, ...args: any[]) {
        return genderLabel[Gender[value]] || '';
    }
}
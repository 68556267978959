import { Component, OnInit, ViewChild, AfterViewInit, Input } from '@angular/core';
import { MapInfoWindow, MapMarker, GoogleMap } from '@angular/google-maps';
import { LatLng } from 'src/app/model/latlng.model';
import { AggressionService } from 'src/app/service/aggression.service';


@Component({
  selector: 'app-map',
  templateUrl: './map.component.html',
  styleUrls: ['./map.component.scss']
})
export class MapComponent implements OnInit {

  @ViewChild(GoogleMap,{static: false}) map: GoogleMap;
  @ViewChild(MapInfoWindow, {static: false}) infoWindow: MapInfoWindow;

  @Input()
  center: LatLng;

  @Input()
  coordinates: LatLng[]

  markers: google.maps.Marker[] = [];
  zoom: number;
  options: google.maps.MapOptions;

  constructor(private readonly aggressionService: AggressionService) { }

  ngOnInit(): void {
    console.log(this.center);
    console.log(this.coordinates);
    console.log(this.coordinates?.length);
    this.zoom = 11;
    this.options = {
      zoomControl: true,
      scrollwheel: true,
      disableDoubleClickZoom: true,
      clickableIcons: true,
      mapTypeId: "roadmap"
    };
    this.markers = this.initMarkers(this.coordinates);
  }

  private initMarkers(coordonates: LatLng[]) : google.maps.Marker[] {
      return coordonates.map(c => {
        const marker = new google.maps.Marker;
        marker.setPosition({
          lat: c.lat,
          lng: c.lng
        }),
        marker.setLabel({
          color : 'red',
        } as google.maps.ReadonlyMarkerLabel ),
        marker.setTitle('Alerte'),
        marker.setOptions({ animation: google.maps.Animation.DROP })
        return marker;
    })
  }
}

import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { GoogleMapsModule } from '@angular/google-maps'
import { NbThemeModule, NbLayoutModule, NbSidebarModule, NbListModule, NbMenuModule, NbIconModule, NbCardModule, NbChatModule, NbButtonModule, NbInputModule, NbToggleModule, NbDialogModule } from '@nebular/theme';
import { NbEvaIconsModule } from '@nebular/eva-icons';
import { MapComponent } from './components/map/map.component';
import { Ng2SmartTableModule } from 'ng2-smart-table';
import { UserService } from './service/user.service';
import { DatePipe, CommonModule } from '@angular/common';
import { NgxEchartsModule } from 'ngx-echarts';
import { EchartsPieComponent } from './components/dashboard/echarts-pie/echarts-pie.component';
import { EchartsBarComponent } from './components/dashboard/echarts-bar/echarts-bar.component';
import { AggressionService } from './service/aggression.service';
import { HttpClientModule } from '@angular/common/http';
import { GeocodingService } from './service/geocoding.service';
import { GenderPipe } from './service/util/gender.pipe';
import { AggressionTypePipe, AggressionPositionPipe, AggressionLocationPipe } from './service/util/aggression.pipe';
import { BirthdatePipe } from './service/util/birthdate.pipe';
import { StatePipe } from './service/util/state.pipe';
import { ViewMapComponent } from './views/view-map/view-map.component';
import { ViewAggressionComponent} from './views/view-aggression-list/view-aggression.component';
import { AggressionDetailsComponent } from './views/view-aggression-list/aggression-details/aggression-details.component';
import { ViewUserComponent } from './views/view-user-list/view-user.component';
import { ViewPoiComponent } from './views/view-poi/view-poi.component';
import { ViewLoginComponent } from './views/view-login/view-login.component';
import { UserDashboardComponent } from './views/view-user-dashboard/user-dashboard.component';
import { AggressionDashboardComponent } from './views/view-aggression-dashboard/aggression-dashboard.component';
import { ConfirmModalComponent } from './components/confirm-modal/confirm-modal.component';

@NgModule({
  declarations: [
    AppComponent,
    MapComponent,
    EchartsPieComponent,
    EchartsBarComponent,
    UserDashboardComponent,
    AggressionDashboardComponent,
    AggressionDetailsComponent,
    GenderPipe,
    BirthdatePipe,
    AggressionTypePipe,
    AggressionPositionPipe,
    AggressionLocationPipe,
    ViewMapComponent,
    ViewAggressionComponent,
    ViewUserComponent,
    ViewPoiComponent,
    ViewLoginComponent,
    ConfirmModalComponent
  ],
  imports: [
    // Routing
    AppRoutingModule,

    // Http
    HttpClientModule,
    
    // Layout
    BrowserModule,
    BrowserAnimationsModule,
    Ng2SmartTableModule,
    NbThemeModule.forRoot({ name: 'corporate' }),
    NbSidebarModule.forRoot(),
    NbSidebarModule,
    NbLayoutModule,
    NbCardModule,
    NbToggleModule,
    GoogleMapsModule,
    NbListModule,
    NbMenuModule.forRoot(),
    NbDialogModule.forRoot(),
    NbChatModule,
    NbEvaIconsModule,
    NbIconModule,
    NbButtonModule,
    NbInputModule,
    CommonModule,
    // Chart 
    NgxEchartsModule
  ],
  providers: [
    UserService,
    AggressionService,
    GeocodingService,
    DatePipe,
    GenderPipe,
    StatePipe,
    BirthdatePipe
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }

import { Aggression, AggressionType, AggressionPosition, AggressionLocation } from '../model/aggression.model';
import { Injectable } from '@angular/core';
import { Comment } from "../model/comment.model";
import { User, State, Gender } from '../model/user.model';
import { Observable } from 'rxjs';

@Injectable()
export class AggressionService {

    getAllAggression = () : Aggression[] => {
        return [
            {
                id : 1,
                latitude: 48.805784,
                longitude: 2.436806,
                description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque lobortis orci et diam egestas, vitae sodales nulla eleifend. Morbi pulvinar augue felis, maximus commodo nunc ultricies at. Ut pretium magna sit amet lacus blandit suscipit. Mauris aliquam mauris lobortis sapien sollicitudin fermentum. Vestibulum varius, leo feugiat fermentum dictum, elit lorem tincidunt felis, vitae facilisis ante enim vel purus. Suspendisse potenti.",
                aggressionType: AggressionType.PHYSICAL,
                aggressionLocation: AggressionLocation.STREET,
                aggressionPosition: AggressionPosition.VICTIM,
                createdAt: new Date()
            },
            {
                id : 1,
                latitude: 48.801148,
                longitude:  2.429443,
                description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque lobortis orci et diam egestas, vitae sodales nulla eleifend. Morbi pulvinar augue felis, maximus commodo nunc ultricies at. Ut pretium magna sit amet lacus blandit suscipit. Mauris aliquam mauris lobortis sapien sollicitudin fermentum. Vestibulum varius, leo feugiat fermentum dictum, elit lorem tincidunt felis, vitae facilisis ante enim vel purus. Suspendisse potenti.",
                aggressionType: AggressionType.PHYSICAL,
                aggressionLocation: AggressionLocation.STREET,
                aggressionPosition: AggressionPosition.VICTIM,
                createdAt: new Date()
            },
            {
                id : 1,
                latitude: 48.824154,
                longitude: 2.379116,
                description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque lobortis orci et diam egestas, vitae sodales nulla eleifend. Morbi pulvinar augue felis, maximus commodo nunc ultricies at. Ut pretium magna sit amet lacus blandit suscipit. Mauris aliquam mauris lobortis sapien sollicitudin fermentum. Vestibulum varius, leo feugiat fermentum dictum, elit lorem tincidunt felis, vitae facilisis ante enim vel purus. Suspendisse potenti.",
                aggressionType: AggressionType.PHYSICAL,
                aggressionLocation: AggressionLocation.STREET,
                aggressionPosition: AggressionPosition.VICTIM,
                createdAt: new Date()
            },
            {
                id : 1,
                latitude: 12,
                longitude: 12,
                description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque lobortis orci et diam egestas, vitae sodales nulla eleifend. Morbi pulvinar augue felis, maximus commodo nunc ultricies at. Ut pretium magna sit amet lacus blandit suscipit. Mauris aliquam mauris lobortis sapien sollicitudin fermentum. Vestibulum varius, leo feugiat fermentum dictum, elit lorem tincidunt felis, vitae facilisis ante enim vel purus. Suspendisse potenti.",
                aggressionType: AggressionType.PHYSICAL,
                aggressionLocation: AggressionLocation.STREET,
                aggressionPosition: AggressionPosition.VICTIM,
                createdAt: new Date()
            },
            {
                id : 1,
                latitude: 12,
                longitude: 12,
                description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque lobortis orci et diam egestas, vitae sodales nulla eleifend. Morbi pulvinar augue felis, maximus commodo nunc ultricies at. Ut pretium magna sit amet lacus blandit suscipit. Mauris aliquam mauris lobortis sapien sollicitudin fermentum. Vestibulum varius, leo feugiat fermentum dictum, elit lorem tincidunt felis, vitae facilisis ante enim vel purus. Suspendisse potenti.",
                aggressionType: AggressionType.PHYSICAL,
                aggressionLocation: AggressionLocation.STREET,
                aggressionPosition: AggressionPosition.VICTIM,
                createdAt: new Date()
            },
            {
                id : 1,
                latitude: 12,
                longitude: 12,
                description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque lobortis orci et diam egestas, vitae sodales nulla eleifend. Morbi pulvinar augue felis, maximus commodo nunc ultricies at. Ut pretium magna sit amet lacus blandit suscipit. Mauris aliquam mauris lobortis sapien sollicitudin fermentum. Vestibulum varius, leo feugiat fermentum dictum, elit lorem tincidunt felis, vitae facilisis ante enim vel purus. Suspendisse potenti.",
                aggressionType: AggressionType.PHYSICAL,
                aggressionLocation: AggressionLocation.STREET,
                aggressionPosition: AggressionPosition.VICTIM,
                createdAt: new Date()
            },
            {
                id : 1,
                latitude: 12,
                longitude: 12,
                description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque lobortis orci et diam egestas, vitae sodales nulla eleifend. Morbi pulvinar augue felis, maximus commodo nunc ultricies at. Ut pretium magna sit amet lacus blandit suscipit. Mauris aliquam mauris lobortis sapien sollicitudin fermentum. Vestibulum varius, leo feugiat fermentum dictum, elit lorem tincidunt felis, vitae facilisis ante enim vel purus. Suspendisse potenti.",
                aggressionType: AggressionType.PHYSICAL,
                aggressionLocation: AggressionLocation.STREET,
                aggressionPosition: AggressionPosition.VICTIM,
                createdAt: new Date()
            },
            {
                id : 1,
                latitude: 12,
                longitude: 12,
                description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque lobortis orci et diam egestas, vitae sodales nulla eleifend. Morbi pulvinar augue felis, maximus commodo nunc ultricies at. Ut pretium magna sit amet lacus blandit suscipit. Mauris aliquam mauris lobortis sapien sollicitudin fermentum. Vestibulum varius, leo feugiat fermentum dictum, elit lorem tincidunt felis, vitae facilisis ante enim vel purus. Suspendisse potenti.",
                aggressionType: AggressionType.PHYSICAL,
                aggressionLocation: AggressionLocation.STREET,
                aggressionPosition: AggressionPosition.VICTIM,
                createdAt: new Date()
            },
            {
                id : 1,
                latitude: 12,
                longitude: 12,
                description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque lobortis orci et diam egestas, vitae sodales nulla eleifend. Morbi pulvinar augue felis, maximus commodo nunc ultricies at. Ut pretium magna sit amet lacus blandit suscipit. Mauris aliquam mauris lobortis sapien sollicitudin fermentum. Vestibulum varius, leo feugiat fermentum dictum, elit lorem tincidunt felis, vitae facilisis ante enim vel purus. Suspendisse potenti.",
                aggressionType: AggressionType.PHYSICAL,
                aggressionLocation: AggressionLocation.STREET,
                aggressionPosition: AggressionPosition.VICTIM,
                createdAt: new Date()
            },
            {
                id : 1,
                latitude: 12,
                longitude: 12,
                description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque lobortis orci et diam egestas, vitae sodales nulla eleifend. Morbi pulvinar augue felis, maximus commodo nunc ultricies at. Ut pretium magna sit amet lacus blandit suscipit. Mauris aliquam mauris lobortis sapien sollicitudin fermentum. Vestibulum varius, leo feugiat fermentum dictum, elit lorem tincidunt felis, vitae facilisis ante enim vel purus. Suspendisse potenti.",
                aggressionType: AggressionType.PHYSICAL,
                aggressionLocation: AggressionLocation.STREET,
                aggressionPosition: AggressionPosition.VICTIM,
                createdAt: new Date()
            },
            {
                id : 1,
                latitude: 12,
                longitude: 12,
                description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque lobortis orci et diam egestas, vitae sodales nulla eleifend. Morbi pulvinar augue felis, maximus commodo nunc ultricies at. Ut pretium magna sit amet lacus blandit suscipit. Mauris aliquam mauris lobortis sapien sollicitudin fermentum. Vestibulum varius, leo feugiat fermentum dictum, elit lorem tincidunt felis, vitae facilisis ante enim vel purus. Suspendisse potenti.",
                aggressionType: AggressionType.PHYSICAL,
                aggressionLocation: AggressionLocation.STREET,
                aggressionPosition: AggressionPosition.VICTIM,
                createdAt: new Date()
            },
            {
                id : 1,
                latitude: 12,
                longitude: 12,
                description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque lobortis orci et diam egestas, vitae sodales nulla eleifend. Morbi pulvinar augue felis, maximus commodo nunc ultricies at. Ut pretium magna sit amet lacus blandit suscipit. Mauris aliquam mauris lobortis sapien sollicitudin fermentum. Vestibulum varius, leo feugiat fermentum dictum, elit lorem tincidunt felis, vitae facilisis ante enim vel purus. Suspendisse potenti.",
                aggressionType: AggressionType.PHYSICAL,
                aggressionLocation: AggressionLocation.STREET,
                aggressionPosition: AggressionPosition.VICTIM,
                createdAt: new Date()
            },
            {
                id : 1,
                latitude: 12,
                longitude: 12,
                description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque lobortis orci et diam egestas, vitae sodales nulla eleifend. Morbi pulvinar augue felis, maximus commodo nunc ultricies at. Ut pretium magna sit amet lacus blandit suscipit. Mauris aliquam mauris lobortis sapien sollicitudin fermentum. Vestibulum varius, leo feugiat fermentum dictum, elit lorem tincidunt felis, vitae facilisis ante enim vel purus. Suspendisse potenti.",
                aggressionType: AggressionType.PHYSICAL,
                aggressionLocation: AggressionLocation.STREET,
                aggressionPosition: AggressionPosition.VICTIM,
                createdAt: new Date()
            },
        ]
    }

    getDetailedAggression = (id:number) : Aggression => {
        
        return {
            id : 1,
            latitude: 48.357,
            longitude: 2.37,
            description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque lobortis orci et diam egestas, vitae sodales nulla eleifend. Morbi pulvinar augue felis, maximus commodo nunc ultricies at. Ut pretium magna sit amet lacus blandit suscipit. Mauris aliquam mauris lobortis sapien sollicitudin fermentum. Vestibulum varius, leo feugiat fermentum dictum, elit lorem tincidunt felis, vitae facilisis ante enim vel purus. Suspendisse potenti.",
            aggressionType: AggressionType.PHYSICAL,
            aggressionLocation: AggressionLocation.STREET,
            aggressionPosition: AggressionPosition.VICTIM,
            createdAt: new Date(),
            comments: [
                {
                    id: 1,
                    message : "Test",
                    createdAt : new Date(),
                    reply : true
                } as Comment,
                {
                    id: 2,
                    message : "Test",
                    createdAt: new Date(),
                    reply: false
                } as Comment,
                {
                    id: 3,
                    message : "Test",
                    createdAt : new Date(),
                    reply: true
                } as Comment
            ],
            owner : {
                id: 1,
                username: 'Floflo',
                mail: 'fchambon@wesquad.io',
                state: State.ACTIF,
                gender: Gender.MALE,
                birthdate: new Date('1992-08-29'),
                createdAt: new Date()
            } as User
        } as Aggression;
    }
}
import { Component } from '@angular/core';
import { NbMenuItem, NbSidebarService } from '@nebular/theme';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {

  constructor(private sidebarService: NbSidebarService) {  }

  title = 'handsaway-back-office';

  items: NbMenuItem[] = [
    {
      title: "Dashboard",
      icon : 'home-outline',
      expanded: false,
      children: [
        {
          title: 'Dashboard Utilisateurs',
          link: "dashboard/users"
        },
        {
          title: 'Dashboard Alertes',
          link: "dashboard/aggressions"
        }
      ],
    },
    {
      title: "Utilisateurs",
      link: '/users',
      icon : 'people-outline'
    },
    {
      title: "Aggressions",
      link: '/aggressions',
      icon : 'alert-triangle-outline'
    },
    {
      title: "Points d'interet",
      link: '/poi',
      icon : 'bulb-outline'
    },
    {
      title: "Carte",
      link : "/map",
      icon : 'compass-outline'
    }
   ];

   toogleSidebar(){
    console.log('click')
    this.sidebarService.toggle(true, 'left');
   }
}

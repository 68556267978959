import { Component, OnInit, AfterViewInit, Input } from '@angular/core';
import { NbThemeService } from '@nebular/theme';
import { element } from 'protractor';

@Component({
  selector: 'app-echarts-pie',
  templateUrl: './echarts-pie.component.html',
  styleUrls: ['./echarts-pie.component.scss']
})
export class EchartsPieComponent implements OnInit,AfterViewInit {

  options: any = {}
  themeSubscription: any;

  @Input('data')
  data: any[];

  constructor(private theme: NbThemeService) {}

  ngOnInit(): void {
  }

  ngAfterViewInit() {
    this.themeSubscription = this.theme.getJsTheme().subscribe(config => {

      const colors: any = config.variables;
      const echarts: any = {
          bg: "#ffffff",
          textColor: "#1a2138",
          axisLineColor: "#1a2138",
          splitLineColor: "#edf1f7",
          itemHoverShadowColor: "rgba(0, 0, 0, 0.5)",
          tooltipBackgroundColor: "#3366ff",
          areaOpacity: "0.7"
      }

      this.options = {
        backgroundColor: echarts.bg,
        color: [colors.warningLight, colors.infoLight, colors.dangerLight, colors.successLight],
        tooltip: {
          trigger: 'item',
          formatter: '{a} <br/>{b} : {c} ({d}%)',
        },
        legend: {
          orient: 'vertical',
          left: 'left',
          data: this.data.map(v => v.name),
          textStyle: {
            color: echarts.textColor,
          },
        },
        series: [
          {
            name: 'Utilisateurs',
            type: 'pie',
            radius: '80%',
            center: ['50%', '50%'],
            data: this.data,
            itemStyle: {
              emphasis: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: echarts.itemHoverShadowColor,
              },
            },
            label: {
              show: false
            },
          },
        ],
      };
    });
  }

}

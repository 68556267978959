<nb-card>
    <nb-card-header>Confirmation de suppression</nb-card-header>
    <nb-card-body>
      <div> Attention Lucile, tu risques de faire des bétises en cliquant sur ce bouton </div>
      <div> Es tu sure de vouloir supprimer l'utilisateur </div>
    </nb-card-body>
    <nb-card-footer class="button-row">
        <button nbButton class="button" status="basic" (click)="onValid()">Oui</button>
        <button nbButton class="button" status="primary" (click)="onCancel()">Non</button>
    </nb-card-footer>
  </nb-card>    

import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { map, take } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { LatLng } from '../model/latlng.model';
@Injectable()
export class GeocodingService {

    private apiUrl: string;

    constructor(private readonly httpClient: HttpClient) {
        this.apiUrl = environment.geocodingUrl;
    }

    /** Example : https://api-adresse.data.gouv.fr/search/?q=75000 */
    geocoding(zipCode: string) : Observable<LatLng> {
        const url = `${this.apiUrl}search/q=${zipCode}`;
        return this.httpClient
            .get<LatLng>(`${this.apiUrl}search/q=${zipCode}`)
            .pipe(take(1))
            .pipe(map((response: any): LatLng => {
                if(response['features'].lenght === 0){
                    return {} as LatLng;
                }
                const coordinates = response['features'][0].geometry.coordinates;
                return {
                    lat: coordinates[0],
                    lng: coordinates[1]
                } as LatLng;
            }));
    }

    /** Example :  "https://api-adresse.data.gouv.fr/reverse/?lon=2.37&lat=48.357" */
    reserveGeocoding(lat: number, lng : number) {
        const url = `${this.apiUrl}reverse/?lon=${lng}&lat=${lat}`;
        return this.httpClient
            .get<GeoCodingJson>(`${this.apiUrl}reverse/?lon=${lng}&lat=${lat}`)
            .pipe(take(1))
            .pipe(map((response :any) : GeoCodingJson => {
                if(response['features'].lenght === 0){
                    return {} as GeoCodingJson;
                }
                const tmp = response['features'][0];
                if(tmp === undefined) { return {} as GeoCodingJson; }
                return {
                    latitude : tmp.geometry.coordinates[0],
                    longitude : tmp.geometry.coordinates[1],
                    fullAddress : tmp.properties.label,
                    zipCode : tmp.properties.postcode,
                    city : tmp.properties.city,
                    street : tmp.properties.street,
                    department : tmp.properties.context
                } as GeoCodingJson;
         }));
    }
}

export interface GeoCodingJson {
    latitude: number;
    longitude: number;
    fullAddress: string; 
    zipCode: string;
    city: string;
    street: string;
    department : string;
}

/**
 * {
   "type":"FeatureCollection",
   "version":"draft",
   "features":[
      {
         "type":"Feature",
         "geometry":{
            "type":"Point",
            "coordinates":[
               2.290084,
               49.897443
            ]
         },
         "properties":{
            "label":"8 Boulevard du Port 80000 Amiens",
            "score":0.49159121588068583,
            "housenumber":"8",
            "id":"80021_6590_00008",
            "type":"housenumber",
            "name":"8 Boulevard du Port",
            "postcode":"80000",
            "citycode":"80021",
            "x":648952.58,
            "y":6977867.25,
            "city":"Amiens",
            "context":"80, Somme, Hauts-de-France",
            "importance":0.6706612694243868,
            "street":"Boulevard du Port"
         }
      }
   ],
   "attribution":"BAN",
   "licence":"ODbL 1.0",
   "query":"8 bd du port",
   "limit":1
}
 */
<google-map
    height="36.5625rem"
    width="100%" 
    [center]="center"    
    [zoom]="zoom" 
    [options]="options">
    
    <map-marker
        *ngFor="let marker of markers"
        [position]="marker.position"
        [label]="marker.label"
        [title]="marker.title"
        [options]="marker.options" >
    </map-marker>
  
</google-map>

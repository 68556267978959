import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthenticationGuard } from './guard/authentication.guard';
import { ViewMapComponent } from './views/view-map/view-map.component';
import { ViewPoiComponent } from './views/view-poi/view-poi.component';
import { UserDashboardComponent } from './views/view-user-dashboard/user-dashboard.component';
import { AggressionDashboardComponent } from './views/view-aggression-dashboard/aggression-dashboard.component';
import { ViewUserComponent } from './views/view-user-list/view-user.component';
import { ViewAggressionComponent } from './views/view-aggression-list/view-aggression.component';
import { AggressionDetailsComponent } from './views/view-aggression-list/aggression-details/aggression-details.component';


const routes: Routes = [
  {
    path: '',
    redirectTo : 'dashboard/users',
    pathMatch : 'full',
    canActivate : [AuthenticationGuard]
  },
  { 
    path : 'dashboard/users', 
    component: UserDashboardComponent,
    canActivate : [AuthenticationGuard] 
  },
  { 
    path : 'dashboard/aggressions', 
    component: AggressionDashboardComponent,
    canActivate : [AuthenticationGuard] 
  },
  { 
    path : 'users', 
    component: ViewUserComponent,
    canActivate: [AuthenticationGuard]
  },
  { 
    path : 'map', 
    component: ViewMapComponent,
    canActivate: [AuthenticationGuard]
  },
  {
    path : 'aggressions',
    component : ViewAggressionComponent,
    canActivate : [AuthenticationGuard]
  },
  {
    path: 'aggressions/details',
    component: AggressionDetailsComponent,
    canActivate: [AuthenticationGuard]
  },
  {
    path : 'poi',
    component : ViewPoiComponent,
    canActivate : [AuthenticationGuard]
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }

import { PipeTransform, Pipe } from '@angular/core';
import { AggressionType, AggressionPosition, AggressionLocation } from 'src/app/model/aggression.model';

export const aggressionTypeLabel = {
    PHYSICAL : 'Aggression physique',
    VERBAL : 'Aggression verbale'
}

@Pipe({
    name: 'aggressionType'
})
export class AggressionTypePipe implements PipeTransform{
    transform(value: any, ...args: any[]) {
        return aggressionTypeLabel[AggressionType[value]] || '';
    }
}

export const aggressionPositionLabel = {
    VICTIM : 'Victime' ,
    WITNESS : 'Témoin'
}

@Pipe({
    name: 'aggressionPosition'
})
export class AggressionPositionPipe implements PipeTransform{
    transform(value: any, ...args: any[]) {
        return aggressionPositionLabel[AggressionPosition[value]] || '';
    }
}

export const aggressionLocationLabel = {
    STREET : 'Aggression dans la rue',
    TRANSPORT : 'Aggression dans les transports'
}

@Pipe({
    name: 'aggressionLocation'
})
export class AggressionLocationPipe implements PipeTransform{
    transform(value: any, ...args: any[]) {
        return aggressionLocationLabel[AggressionLocation[value]] || '';
    }
}
import { Component, OnInit, AfterViewInit, Input } from '@angular/core';
import { NbThemeService } from '@nebular/theme';

@Component({
  selector: 'app-echarts-bar',
  templateUrl: './echarts-bar.component.html',
  styleUrls: ['./echarts-bar.component.scss']
})
export class EchartsBarComponent implements OnInit,AfterViewInit {

  options: any = {}
  themeSubscription: any;

  @Input('data')
  data: any[];

  constructor(private theme: NbThemeService) {}

  ngOnInit(): void {
  }

  ngAfterViewInit() {
    this.themeSubscription = this.theme.getJsTheme().subscribe(config => {
      const colors: any = config.variables;
      console.log(colors);
      const echarts: any = {
        bg: "#ffffff",
        textColor: "#1a2138",
        axisLineColor: "#1a2138",
        splitLineColor: "#edf1f7",
        itemHoverShadowColor: "rgba(0, 0, 0, 0.5)",
        tooltipBackgroundColor: "#3366ff",
        areaOpacity: "0.7"
    }
      this.options = {
        backgroundColor: echarts.bg,
        color: ["rgba(255,95,95,1)"],
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'shadow',
          },
        },
        grid: {
          left: '3%',
          right: '4%',
          bottom: '3%',
          containLabel: true,
        },
        xAxis: [
          {
            type: 'category',
            data: this.data.map(e => e.name),
            axisTick: {
              alignWithLabel: true,
            },
            axisLine: {
              lineStyle: {
                color: echarts.axisLineColor,
              },
            },
            axisLabel: {
              textStyle: {
                color: echarts.textColor,
              },
            },
          },
        ],
        yAxis: [
          {
            type: 'value',
            axisLine: {
              lineStyle: {
                color: echarts.axisLineColor,
              },
            },
            splitLine: {
              lineStyle: {
                color: echarts.splitLineColor,
              },
            },
            axisLabel: {
              textStyle: {
                color: echarts.textColor,
              },
            },
          },
        ],
        series: [
          {
            name: 'Score',
            type: 'bar',
            barWidth: '60%',
            data: this.data.map(e => e.value)
          },
        ],
      };
    });
  }

}

import { User } from './user.model';
import { Comment} from './comment.model';

export interface Aggression {
    id: number;
    latitude: number;
    longitude: number;
    description: string;
    aggressionType: AggressionType;
    aggressionLocation: AggressionLocation;
    aggressionPosition: AggressionPosition;
    createdAt: Date;

    comments?: Comment[];
    owner?: User;
}

export enum AggressionType {
    PHYSICAL = 1,
    VERBAL = 2,
}

export enum AggressionPosition {
    VICTIM = 1,
    WITNESS = 2
}   

export enum AggressionLocation {
    STREET = 1,
    TRANSPORT = 2
}
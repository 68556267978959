<div class="row">
    <div class="col-lg-12">
        <nb-card>
            <nb-card-header> Test </nb-card-header>
            <nb-card-body>
                Il y a {{ data.nbUser }} utilisateurs enregistrés dans la bdd
            </nb-card-body>
        </nb-card>
    </div>
</div>
<div class="row">
    <div class="col-lg-6">
        <nb-card>
            <nb-card-header> Utilisateurs </nb-card-header>
            <nb-card-body>
                <app-echarts-pie [data]="data.userTypeChart"></app-echarts-pie>
            </nb-card-body>
        </nb-card>
    </div>

    <div class="col-lg-6">
        <nb-card>
            <nb-card-header> Inscription </nb-card-header>
            <nb-card-body>
                <app-echarts-bar [data]="data.inscriptionChart"></app-echarts-bar>
            </nb-card-body>
        </nb-card>
    </div>
</div>

<div class="row">
    <div class="col-lg-12">
        <nb-card>
            <nb-card-header> Utilisateurs par tranche d'age </nb-card-header>
            <nb-card-body>
                <app-echarts-bar [data]="data.userAgeChart"></app-echarts-bar>
            </nb-card-body>
        </nb-card>
    </div>
</div>
<div class="row">
    <div class="col-lg-12">
        <nb-card>
            <nb-card-header>
                Détails de l'alerte
            </nb-card-header>
            <nb-card-body >

                <div *ngIf="aggressionDetails">
                    <h4> Alerte n° {{ aggressionDetails.id }} </h4>
                    <div> Date : {{ aggressionDetails.createdAt | date:'full' }} </div>
                    <div> Heure : {{ aggressionDetails.createdAt | date : 'mediumTime' }} </div>
                    <div> {{ aggressionDetails.aggressionType | aggressionType }} </div>
                    <div> {{ aggressionDetails.aggressionPosition | aggressionPosition }} </div>
                    <div> {{ aggressionDetails.aggressionLocation | aggressionLocation }} </div>
                    <div> {{ aggressionDetails.owner.gender | gender }} </div>
                    <div> {{ aggressionDetails.owner.birthdate | age }} ans</div>
                </div>
                
                <div class="mt-2" *ngIf="geocodingJson">
                    <h6> Addresse de l'alerte </h6>
                    <div> Latitude : {{ geocodingJson.latitude }} et Longitude : {{ geocodingJson.longitude }}</div>
                    <div> Addresse : {{ geocodingJson.fullAddress }} </div>
                    <div> Ville : {{ geocodingJson.city }} </div>
                    <div> Rue : {{ geocodingJson.street }} </div>
                    <div> Département et Région : {{ geocodingJson.department }}</div>
                </div>
                
                <p *ngIf="aggressionDetails.description" class="mt-3 paragraph"> 
                    Description : {{ aggressionDetails.description }} 
                </p>
            </nb-card-body>
        </nb-card>
    </div>

    <!--<div *ngIf="aggressionDetails.comments" class="col-lg-6">
       <nb-chat title="Commentaires de l'alerte">
           <nb-chat-message *ngFor="let comments of aggressionDetails.comments"
            [message]="comments.message"
            [date]="comments.createdAt"
            [reply]="comments.reply"></nb-chat-message>
       </nb-chat>
    </div>
    -->
</div>
<div class="row mt-5">
    <div class="col-lg-12">
        <app-map [center]="position" [coordinates]="coordinates"></app-map>
    </div>
</div>
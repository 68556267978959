<div class="row">
    <div class="col-lg-12" style="overflow:scroll;">
        <table class="table">
            <ng2-smart-table class="custom-table"
                [source]="aggressions" 
                [settings]="settings"
                (deleteConfirm)="deleteAggression($event)"
                (userRowSelect)="route($event)">
            </ng2-smart-table>
        </table>
    </div>
</div>


import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-user-dashboard',
  templateUrl: './user-dashboard.component.html',
  styleUrls: ['./user-dashboard.component.scss']
})
export class UserDashboardComponent implements OnInit {

  data:any;
  userGender : any[];
  newUser : any[];

  constructor() { }

  ngOnInit(): void {
    this.data = {
      nbUser : 1400,
      userTypeChart: [
        { value: 335, name: 'Homme' },
        { value: 310, name: 'Femme' },
        { value: 234, name: 'Non Binaire' },
        { value: 135, name: 'Inconnu' }
      ],
      userAgeChart: [
        { name : '11 - 14', value: 45},
        { name : '15 - 17', value: 100},
        { name : '18 - 20', value: 200},
        { name : '21 - 25', value: 400},
        { name : '26 - 30', value: 200},
        { name : '31 - 40', value: 100},
        { name : '51 - 70', value: 140},
        { name : '71 +', value: 160},
        { name : 'Autres', value: 400}
      ],
      inscriptionChart : [
        { name: 'Jan', value: 12 },
        { name: 'Fev', value: 15 },
        { name: 'Mar', value: 8 },
        { name: 'Avr', value: 5 },
        { name: 'Mai', value: 7 },
        { name: 'Jui', value: 10 },
        { name: 'Jui', value: 22 },
        { name: 'Aou', value: 9 },
        { name: 'Sep', value: 6 },
        { name: 'Oct', value: 19 },
        { name: 'Nov', value: 15 },
        { name: 'Dec', value: 12 }
      ]
    };
  }
}

import { Injectable } from '@angular/core';
import { User, State, Gender } from '../model/user.model';

@Injectable()
export class UserService {

    getAllUser = () : User[] => {
        const data = [
            {
                id: 1,
                username : "Floflo",
                mail : "fchambon@wesquad.io",
                state: State.ACTIF,
                gender : Gender.MALE,
                birthdate : new Date("1992 08 29"),
                createdAt : new Date()
            },
            {
                id: 2,
                username : "Floflo",
                mail : "fchambon@wesquad.io",
                state: State.ACTIF,
                gender : Gender.MALE,
                birthdate : new Date(),
                createdAt : new Date()
            },
            {
                id: 2,
                username : "Floflo",
                mail : "fchambon@wesquad.io",
                state: State.ACTIF,
                gender : Gender.MALE,
                birthdate : new Date(),
                createdAt : new Date()
            },
            {
                id: 2,
                username : "Floflo",
                mail : "fchambon@wesquad.io",
                state: State.ACTIF,
                gender : Gender.MALE,
                birthdate : new Date(),
                createdAt : new Date()
            },
            {
                id: 2,
                username : "Floflo",
                mail : "fchambon@wesquad.io",
                state: State.ACTIF,
                gender : Gender.MALE,
                birthdate : new Date(),
                createdAt : new Date()
            },
            {
                id: 2,
                username : "Floflo",
                mail : "fchambon@wesquad.io",
                state: State.ACTIF,
                gender : Gender.MALE,
                birthdate : new Date(),
                createdAt : new Date()
            },
            {
                id: 2,
                username : "Floflo",
                mail : "fchambon@wesquad.io",
                state: State.ACTIF,
                gender : Gender.MALE,
                birthdate : new Date(),
                createdAt : new Date()
            },
            {
                id: 2,
                username : "Floflo",
                mail : "fchambon@wesquad.io",
                state: State.ACTIF,
                gender : Gender.MALE,
                birthdate : new Date(),
                createdAt : new Date()
            },
            {
                id: 2,
                username : "Floflo",
                mail : "fchambon@wesquad.io",
                state: State.ACTIF,
                gender : Gender.MALE,
                birthdate : new Date(),
                createdAt : new Date()
            },
            {
                id: 2,
                username : "Floflo",
                mail : "fchambon@wesquad.io",
                state: State.ACTIF,
                gender : Gender.MALE,
                birthdate : new Date(),
                createdAt : new Date()
            },
            {
                id: 2,
                username : "Floflo",
                mail : "fchambon@wesquad.io",
                state: State.ACTIF,
                gender : Gender.MALE,
                birthdate : new Date(),
                createdAt : new Date()
            }
        ] as User[]
        return data;
    }

    deleteUser = (idUser: number) => {
        return null;
    }
}
<nb-layout>
  <nb-layout-header class="header-container" fixed>
    <button class="button" nbButton ghost (click)="toogleSidebar()">
      <nb-icon icon="menu-2-outline">
      </nb-icon>
    </button>
    <h1 class="title">Back Office Handsaway </h1>
  </nb-layout-header>

  <nb-sidebar>
    <nb-menu [items]="items">
    </nb-menu>
  </nb-sidebar>

  <nb-layout-column>
    <router-outlet>
    </router-outlet>
  </nb-layout-column>

  <nb-layout-footer class="footer" fixed>
    <div>Handsaway</div>
  </nb-layout-footer>
</nb-layout>

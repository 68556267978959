import { Component, OnInit, OnDestroy } from '@angular/core';
import { AggressionService } from '../../service/aggression.service';
import { GeocodingService } from '../../service/geocoding.service';
import { Subscription } from 'rxjs';
import { Aggression } from '../../model/aggression.model';
import { DatePipe } from '@angular/common';
import { Router } from '@angular/router';
import { NbDialogRef } from '@nebular/theme';

@Component({
  selector: 'app-view-aggression',
  templateUrl: './view-aggression.component.html',
  styleUrls: ['./view-aggression.component.scss']
})
export class ViewAggressionComponent implements OnInit,OnDestroy {
  
  subscription : Subscription;
  aggressions : Aggression[];
  settings = {
    actions : {
      add : false,
      edit : true,
      delete : true
    },
    edit : {
      editButtonContent: '<i class="eva eva-edit-2"></i>',
      confirmEdit: true
    },
    delete: {
      deleteButtonContent: '<i class="eva eva-trash-2"></i>',
      confirmDelete: true
    },
    noDataMessage : 'Ah Bah c\'est cassé la',
    columns: {
      id: {
        title: 'ID'
      },
      latitude: {
        title: 'Latitude'
      },
      longitude : {
        title: 'Longitude'
      },
      description : {
        title : 'Description'
      },
      aggressionType : {
        title : 'Type',
        filter: {
          type: 'list',
          config: {
            selectText: 'Select...',
            list: [
              { value: 1, title: 'Aggression Physique' },
              { value: 2, title: 'Aggression Verbale' }
            ],
          },
        },
        valuePrepareFunction : (value: number) => {
          return this.displayAggressionTypeValue(value);
        }
      },
      aggressionPosition : {
        title : 'Position',
        filter: {
          type: 'list',
          config: {
            selectText: 'Select...',
            list: [
              { value: 1, title: 'Victime' },
              { value: 2, title: 'Témoin' }
            ],
          },
        },
        valuePrepareFunction : (value: number) => {
          return this.displayAggressionPositionValue(value);
        }
      },
      aggressionLocation : {
        title : 'Location',
        filter: {
          type: 'list',
          config: {
            selectText: 'Select...',
            list: [
              { value: 1, title: 'Rue' },
              { value: 2, title: 'Transport' }
            ],
          },
        },
        valuePrepareFunction : (value: number) => {
          return this.displayAggressionLocationValue(value);
        }
      },
      createdAt : {
        title : "Date et Heure",
        valuePrepareFunction : (createdAt : Date) => {
          return this.datePipe.transform(createdAt,'medium');
        }
      }
    }
  };
  constructor(
    private readonly aggressionService: AggressionService,
    private readonly geocodingService: GeocodingService,
    private readonly datePipe : DatePipe,
    private readonly router: Router) { }

  ngOnInit(): void {
    this.aggressions = this.aggressionService.getAllAggression();
    this.subscription = this.geocodingService.reserveGeocoding(48.357,2.37).subscribe(e => console.log(e));
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  route(event) {
    console.log(event);
    this.router.navigate(['aggressions/details', event.data.id]);
  }

  deleteAggression($event:any) {
    console.log($event);
    console.log('delete');
  } 

  /** USE PIPE INSTEAD */

  displayAggressionPositionValue(key: number) {
    switch(key){
      case 1 : return "Victime"
      case 2 : return "Témoin"
      default : return ""
    }
  }

  displayAggressionLocationValue(key: number){
    switch(key){
      case 1 : return "Rue"
      case 2 : return "Transport en communs"
      default : return ""
    }
  }

  displayAggressionTypeValue(key: number){
    switch(key){
      case 1 : return "Aggression Physique"
      case 2 : return "Aggression Verbale"
      default : return ""
    }
  }

}
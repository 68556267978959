<div class="row">
    <div class="col-lg-12">
        <nb-card>
            <nb-card-header>
                Aggressions
            </nb-card-header>
            <nb-card-body>
                On enregistre aujourd'hui {{ nbAggression }} alertes depuis le lancement de l'application
            </nb-card-body>
        </nb-card>
    </div>
</div>

<div class="row">
    <div class="col-lg-4">
        <nb-card>
            <nb-card-body>
                <app-echarts-pie [data]="data.aggressionLocation"></app-echarts-pie>
            </nb-card-body>
        </nb-card>
    </div>

    <div class="col-lg-4">
        <nb-card>
            <nb-card-body>
                <app-echarts-pie [data]="data.aggressionPosition"></app-echarts-pie>
            </nb-card-body>
        </nb-card>
    </div>

    <div class="col-lg-4">
        <nb-card>
            <nb-card-body>
                <app-echarts-pie [data]="data.aggressionType"></app-echarts-pie>
            </nb-card-body>
        </nb-card>
    </div>
</div>

<div class="row">
    <div class="col-lg-12">
        <nb-card>
            <nb-card-header>
                Alertes par mois
            </nb-card-header>
            <nb-card-body>
                <app-echarts-bar [data]="data.aggressionMonthChartBar"></app-echarts-bar>
            </nb-card-body>
        </nb-card>
    </div>
</div>

<div class="row">
    <div class="col-lg-12">
        <nb-card>
            <nb-card-header>
                Alertes par tranche horaire
            </nb-card-header>
            <nb-card-body>
                <app-echarts-bar [data]="data.aggressionHoursChartBar"></app-echarts-bar>
            </nb-card-body>
        </nb-card>
    </div>
</div>

import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-aggression-dashboard',
  templateUrl: './aggression-dashboard.component.html',
  styleUrls: ['./aggression-dashboard.component.scss']
})
export class AggressionDashboardComponent implements OnInit {


  data: any;

  nbAggression = 1400;
  aggressionLocation: any[];
  aggressionPosition: any[];
  aggressionType: any[];
  aggressionBar : any[]
  aggressionHours: any[]

  constructor() { }

  ngOnInit(): void {

    this.data = {
      nbAggression: 1400,
      aggressionType : [
        { value : 345, name: "Physique" },
        { value : 654, name: "Verbale" }
      ],
      aggressionPosition : [
        { value : 345, name: "Victime" },
        { value : 654, name: "Témoins" }
      ],
      aggressionLocation : [
        { value : 345, name: "Rue" },
        { value : 654, name: "Transport" }
      ],
      aggressionMonthChartBar : [
        { name: 'Jan', value: 12 },
        { name: 'Fev', value: 15 },
        { name: 'Mar', value: 8 },
        { name: 'Avr', value: 5 },
        { name: 'Mai', value: 7 },
        { name: 'Jui', value: 10 },
        { name: 'Jui', value: 22 },
        { name: 'Aou', value: 9 },
        { name: 'Sep', value: 6 },
        { name: 'Oct', value: 19 },
        { name: 'Nov', value: 15 },
        { name: 'Dec', value: 12 }
      ],
      aggressionHoursChartBar : [
        { name: '0h-1h', value: 12 },
        { name: '1h-2h', value: 30 },
        { name: '2h-3h', value: 56 },
        { name: '3h-4h', value: 12 },
        { name: '4h-5h', value: 28 },
        { name: '5h-6h', value: 28 },
        { name: '6h-7h', value: 54 },
        { name: '7h-8h', value: 34 },
        { name: '8h-9h', value: 40 },
        { name: '9h-10h', value: 15 },
        { name: '10h-11h', value: 37 },
        { name: '11h-12h', value: 28 },
        { name: '12h-13h', value: 22 },
        { name: '13h-14h', value: 28 },
        { name: '14h-15h', value: 25 },
        { name: '15h-16h', value: 30 },
        { name: '16h-17h', value: 37 },
        { name: '17h-18h', value: 43 },
        { name: '18h-19h', value: 50 },
        { name: '19h-20h', value: 54 },
        { name: '20h-21h', value: 60 },
        { name: '21h-22h', value: 45 },
        { name: '22h-23h', value: 15 },
        { name: '23h-0h', value: 15 },
      ]
    }
  }
}



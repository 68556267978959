import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Aggression } from 'src/app/model/aggression.model';
import { AggressionService } from 'src/app/service/aggression.service';
import { GeocodingService, GeoCodingJson } from 'src/app/service/geocoding.service';
import { LatLng } from 'src/app/model/latlng.model';

@Component({
  selector: 'app-aggression-details',
  templateUrl: './aggression-details.component.html',
  styleUrls: ['./aggression-details.component.scss']
})
export class AggressionDetailsComponent implements OnInit {

  position: LatLng;
  coordinates: LatLng[] = [];

  geocodingJson : GeoCodingJson;
  aggressionDetails: Aggression;
  constructor(private readonly route: ActivatedRoute,
              private readonly aggressionService: AggressionService,
              private readonly geocodingService: GeocodingService) { }

  async ngOnInit() {
    this.aggressionDetails = this.aggressionService.getDetailedAggression(1);
    this.position = { lat: this.aggressionDetails.latitude, lng : this.aggressionDetails.longitude };
    this.coordinates = [this.position];
    this.geocodingJson = await this.geocodingService.reserveGeocoding(this.position.lat,this.position.lng).toPromise()
  }

}

export interface User {
    id: number;
    username: string;
    mail:string;
    state: State;
    gender: Gender;
    birthdate: Date;
    createdAt: Date;
}

export enum State {
    PENDING = 1,
    ACTIF = 2,
    DISABLE_BY_ADMIN = 3,
    DISABLE_BY_USER_HIMSELF = 4
}

export enum Gender {
    MALE = 1,
    FEMALE = 2,
    NO_BINARY = 3,
    NO_ANSWER = 4
}
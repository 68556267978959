import { PipeTransform, Pipe } from '@angular/core';
import { State } from 'src/app/model/user.model';

export const stateLabel = {
    PENDING : 'Pending...',
    ACTIF : 'Actif',
    DISABLE_BY_ADMIN : 'Désactivé par un administrateur',
    DISABLE_BY_USER_HIMSELF : 'Desactivé par l\'utilisateur'
}

@Pipe({
    name: 'state'
})
export class StatePipe implements PipeTransform {
    transform(value: any, ...args: any[]) {
        return stateLabel[State[value]] || '';
    }
}
import { Component, OnInit } from '@angular/core';
import { UserService } from 'src/app/service/user.service';
import { BirthdatePipe } from 'src/app/service/util/birthdate.pipe';
import { GenderPipe } from 'src/app/service/util/gender.pipe';
import { DatePipe } from '@angular/common';
import { StatePipe } from 'src/app/service/util/state.pipe';
import { User } from 'src/app/model/user.model';
import { NbDialogService, NbDialogRef } from '@nebular/theme';
import { ConfirmModalComponent } from '../../components/confirm-modal/confirm-modal.component';

@Component({
  selector: 'app-view-user',
  templateUrl: './view-user.component.html',
  styleUrls: ['./view-user.component.scss']
})
export class ViewUserComponent implements OnInit {

  constructor(
              private dialogService: NbDialogService,
              private readonly userService: UserService, 
              private readonly datePipe: DatePipe ,
              private readonly genderPipe: GenderPipe,
              private readonly statePipe: StatePipe,
              private readonly birthdatePipe: BirthdatePipe) {  }


  settings = {
      actions : {
        add : false,
        edit : true,
        delete : true
      },
      edit : {
        editButtonContent: '<i class="eva eva-edit-2"></i>',
        saveButtonContent: '<i class="eva eva-checkmark"></i>',
        cancelButtonContent: '<i class="eva eva-close"></i>',
        confirmSave: true
      },
      delete: {
        deleteButtonContent: '<i class="eva eva-trash-2"></i>',
        confirmDelete: true
      },
      
    columns: {
      id: {
        title: 'ID',
        editable : false
      },
      username: {
        title: 'Pseudonyme'
      },
      mail : {
        title: 'Adresse mail'
      },
      state : {
        title : 'Status',
        filter: {
          type: 'list',
          config: {
            selectText: 'Select...',
            list: [
              { value: 1, title: 'Pending' },
              { value: 2, title: 'Actif' },
              { value: 3, title: 'Désactivé par l\'utilisateur' },
              { value: 4, title: 'Désactivé par l\'administrateur' }
            ],
          },
        },
        valuePrepareFunction: (state) => {
          return this.statePipe.transform(state);
        },
        
      },
      gender : {
        title : "Genre",
        filter: {
          type: 'list',
          config: {
            selectText: 'Select...',
            list: [
              { value: 1, title: 'Homme' },
              { value: 2, title: 'Femme' },
              { value: 3, title: 'Non binaire' },
              { value: 4, title: 'Pas de réponse' }
            ],
          },
        },
        valuePrepareFunction: (gender) => {
          return this.genderPipe.transform(gender);
        }
      },
      birthdate : {
        title : "Age",
        filter: {
          type: 'list',
          config: {
            selectText: 'Select...',
            list: [
              { value: 1, title: '11-14 ans' },
              { value: 2, title: '15-17 ans' },
              { value: 3, title: '18-20 ans' },
              { value: 4, title: '21-25 ans' },
              { value: 5, title: '26-30 ans' },
              { value: 6, title: '31-40 ans' },
              { value: 7, title: '41-50 ans' },
              { value: 8, title: '51-70 ans' },
              { value: 9, title: '71+ ans' }
            ],
          },
        },
        valuePrepareFunction: (birthdate) => {
          return this.birthdatePipe.transform(birthdate) + " Ans";
        },
        filterFunction(birthdate?: any, search?: number): boolean {
          const ageDifMs = Date.now() - new Date(birthdate).getTime();
          const ageDate = new Date(ageDifMs);
          const age:number = Number(Math.abs(ageDate.getUTCFullYear() - 1970));
          let value: boolean;
          switch(+search){
            case 1: {
              (age >= 11 && age <= 14) ? value = true : value = false;
              break;
            } 
            case 2: {
              (age >= 15 && age <= 17) ? value = true : value = false;
              break;
            }
            case 3:
              (age >= 18 && age <= 20) ? value = true : value = false;
              break;
            case 4:
              (age >= 21 && age <= 26) ? value = true : value = false;
              break;
            case 5:
              (age >= 26 && age <= 30) ? value = true : value = false;
              break;
            case 6:
              (age >= 31 && age <= 40) ? value = true : value = false;
              break;
            case 7:
              (age >= 41 && age <= 50) ? value = true : value = false;
              break;
            case 8:
              (age >= 51 && age <= 70) ? value = true : value = false;
              break;
            case 9:
              (age >= 71) ? value = true : value = false;
              break;
          }
          return value;
        }
      },
      createdAt : {
        title : "Enregistrement",
        valuePrepareFunction: (created) => {
          return this.datePipe.transform(new Date(created), 'dd MMM yyyy');
        }
      }
    }
  };

  users: User[];
 
  ngOnInit(): void {
    this.users = this.userService.getAllUser();
  }

  onDelete($event: any) {
    console.log($event);
    console.log('delete');
  }

  onDeleteConfirm($event: any) {
    console.log($event);
    console.log('deleteConfirm');
    this.dialogService
      .open(ConfirmModalComponent)
      .onClose
      .subscribe( (value: any) => {
        console.log(value);
        if(value === 'Yes'){
          $event.confirm.resolve();
          this.userService.deleteUser($event.data.id)
        }
      });
  }
}
